/* You can add global styles to this file, and also import other style files */

// @formatter:off
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1380px,
);
// @formatter:on

$border-radius: 2px;

@import "colors";
@import "font";
@import "~bootstrap/scss/bootstrap";
@import '../node_modules/@angular/material/prebuilt-themes/purple-green.css';
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";

body {
  background-repeat: no-repeat;
  background-color: $main-background;
  background-size: cover;
}

.btn {
  border-radius: 1.5rem;
}

.btn-flat {
  border-radius: 0;
}

.form-control {
  border-radius: 1.5rem;
}

.form-control:disabled, .form-control[readonly] {
  background-color: $kx-selection-grey !important;
}

.kx-input-group .kx-input {
  margin: 0 !important;
  padding-top: 0 !important;
}

.kx-input-group-label {
  color: $secondary;
  padding-left: 10px;
}

.kx-input-group app-kx-input:first-child div.kx-input {
  padding-top: 5px !important;
}
.kx-input-group {
  margin: 5px;
}

.switchery {
  height: 24px !important;
  width: 48px !important;
}

.switchery>small {
  margin-top: -3px;
  height: 30px !important;
  width: 30px !important;
}

.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}
@each $name,$color in (default,#777),(primary,$primary),(success,$success),(danger,$danger)
                      ,(warning,$warning),(info,$kx-light-blue) {
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}

*{
  font-family: Arial;
}

// Headers
h1,h2,h3,h4,h5,h6,.header-title{
  font-weight: bold;
  letter-spacing: 0.016em;
}
h1{
  font-size: 60px;
  line-height: 72px;
}
h2{
  font-size: 48px;
  line-height: 61px;
}
h3{
  font-size: 40px;
  line-height: 58px;
}
h4{
  font-size: 30px;
  line-height: 44px;
}
h5{
  font-size: 24px;
  line-height: 38px;
}
h6{
  font-size: 18px;
  line-height: 29px;
}
.header-title{
  font-size: 18px;
  color: $kx-dark-blue;
}
.caption{
  font-size: 14px;
  line-height: 16px;
  &.sm-caption{
    font-size: 12px;
  }
}

// Cursor
.cursor-pointer{
  cursor: pointer;
}
.cursor-auto{
  cursor: auto !important;
}

// Breadcrumbs
ul.breadcrumb{
  background: none;
}
ul.breadcrumb li{
  display: inline;
  font-size: 18px;
  color: #787877;
  +li:before{
    content: "\00a0/\00a0";
  }
  +li:last-child{
    color: $kx-red;
  }
}

// Text input
input.form-control{
  width: 500px;
  height: 43px;
  background: none;
  font-size: 16px;
  color: $kx-input-text;
  border: 2px solid $kx-input-border;
  border-radius: 0px;
  &:hover:enabled {
    border: 2px solid $kx-input-border-hover;
  }
  &:focus {
    border: 2px solid $kx-red;
    box-shadow: none;
    caret-color: $kx-red;
    color: $kx-dark-grey;
  }
  &[required] {
    &:valid{
      border-color: $kx-dark-grey;
      color: $kx-dark-grey;
    }
    &.validated{
      &:invalid {
        border-color: $kx-error;
        color: $kx-error;
      }
    }
  }
}

// Text area
textarea.form-control{
  width: 500px;
  height: 43px;
  background: none;
  font-size: 16px;
  color: $kx-input-text;
  border: 2px solid $kx-input-border;
  border-radius: 0px;
  &::placeholder{
    color: $kx-light-grey;
  }
  &:hover {
    border: 2px solid $kx-input-border-hover;
  }
  &:focus {
    border: 2px solid $kx-red;
    box-shadow: none;
    caret-color: $kx-red;
    color: $kx-dark-grey;
  }
  &[required] {
    &:valid{
      border-color: $kx-dark-grey;
      color: $kx-dark-grey;
    }
    &.validated{
      &:invalid {
        border-color: $kx-error;
        color: $kx-error;
      }
    }
  }
}

textarea.qct-form-control {
  border-width: 1px;
  border-radius: 2px;

  &:hover, &:focus {
    border-width: 1px;
  }
}

// Buttons
button.btn-primary{
  background-color: $kx-red;
  border: none;
  border-radius: 2px;
  height: 43px;
  color: #fff;
  &:active:enabled {
    opacity: 1;
    background-color: $kx-button-pressed !important;
  }
  &:hover:enabled {
    background-color: $kx-button-hover;
  }
  &:disabled{
    background-color: $kx-red;
    cursor: auto;
  }
}
button.btn-secondary {
  background-color: $kx-dark-blue;
  border: none;
  border-radius: 2px;
  height: 43px;
  color: #fff;
  &:active:enabled {
    opacity: 1;
    background-color: $kx-blue !important;
  }
  &:hover:enabled {
    background-color: rgba($kx-dark-blue, 0.8);
  }
  &:disabled{
    background-color: $kx-dark-blue;
    cursor: auto;
  }
}
button.btn-kx-blue {
  background-color: $kx-button-light-blue;
  color: $kx-blue;

  &.active {
    background-color: $kx-blue;
    color: #fff;
  }
}
button.btn-link {
  color: $kx-red;
  &:active:enabled {
    color: $kx-button-pressed !important;
    text-decoration: none;
  }
  &:hover:enabled {
    color: rgba($kx-button-hover, 0.8);
    text-decoration: none;

    img {
      opacity: 0.8;
    }
  }
  &:disabled{
    color: $kx-red;
    text-decoration: none;
    cursor: auto;
  }
}
button.btn-danger {
  background-color: $kx-error;
  border: none;
  border-radius: 2px;
  height: 40px;
  color: #fff;
  &:active:enabled {
    opacity: 1;
    background-color: $kx-error !important;
  }
  &:hover:enabled {
    background-color: $kx-error;
  }
  &:disabled{
    background-color: $kx-error;;
    cursor: auto;
  }
}

.btn-with-arrow{
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
  .btn-link{
    &:hover{
      color: $kx-red;
    }
  }
}
button.btn-sm {
  height: 30px;
}

// Icon before
.icon-before {
  position: relative;
  top: 0.1em;

  &::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5rem;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}

.button-left-arrow::before {
  background-image: url("assets/button-arrow-left.svg")
}
.button-right-arrow::before {
  background-image: url("assets/button-arrow-left.svg");
  transform: rotate(180deg)
}

// NG-Select
ng-select.dialog-select{
    &.ngp-select{
      .ng-select-container{
        border-color: $kx-light-grey;
        height: 40px;
      }
    }
    .ng-select-container{
      border-color: $kx-dark-grey;
      border-radius: 0%;
      caret-color: transparent;
      .ng-value-container{
        .ng-value{
          background-color: transparent;
        }
      }
    }
    .ng-arrow-wrapper{
      background-image: url('assets/icons/expand_more-24px.svg');
      height: 100%;
      background-position: left;
      background-repeat: no-repeat;
      background-size: 20px;
      .ng-arrow{
        border: none;
      }
    }
    &.ng-select-opened {
      > .ng-select-container {
        border-color: $kx-dark-grey;
      }
      .ng-arrow-wrapper{
        background-image: url('assets/icons/expand_less-24px.svg');
        height: 100%;
        background-position: left;
        background-repeat: no-repeat;
        background-size: 20px;
        .ng-arrow{
          border: none;
        }
      }
    }
    &.ng-select-focused {
      &:not(.ng-select-opened) > .ng-select-container {
        border-color: $kx-dark-grey;
        box-shadow: none !important;
      }
  }
  .ng-dropdown-panel{
    background-color: #fff;
    border-color: $kx-dark-grey;
    border-radius: 0%;
    .ng-option{
      &.ng-option-marked {
        color: #fff;
        background-color: $kx-red !important;
      }
    }
    &.ng-select-top {
      .ng-dropdown-panel-items {
        .ng-option {
            &:first-child {
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
            }
        }
    }
    }
    &.ng-select-bottom {
      .ng-dropdown-panel-items {
        .ng-option {
            &:last-child {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
    }
    }
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $kx-red !important;
  color: #fff;
}
#assign-user-select .ng-select-container {
  border-color: $kx-light-grey;
}

// Checkbox
.checkbox-wrapper{
  input[type="checkbox"]{
    display: none;
    &:checked ~ .checkbox{
      background-color: $kx-red;
      &:after{
        display: block;
      }
      &:hover{
        background-color: rgba($kx-red, 0.8);
      }
      &:disabled{
        background-color: $kx-red;
      }
    }
    &:disabled ~ .checkbox{
      border: 1px solid rgba($kx-input-border,0.2);
    }
    &:disabled:checked ~ .checkbox{
      background-color: rgba($kx-red,0.5);
    }
  }
  .checkbox{
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid $kx-input-border;
    &.sm-checkbox{
      width: 15px;
      height: 15px;
      &:after{
        left: 4px;
        height: 9px;
        width: 5px;
      }
    }
    &:hover{
      border-color: $kx-dark-grey;
    }
    &:after{
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 1px;
      width: 6px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

// Alert
.alert {
  border-radius: 2px;
  border: 0;
}

.alert-danger {
  color: #E74C3C;
  background-color: #E74C3C33;
}
.alert-warning {
  color: $kx-secondary-color;
  background-color: rgba($kx-warning, 0.33);
}
.alert-success {
  color: $kx-blue;
  background-color: $kx-light-blue;
}

// Pagination
pagination-controls.pagination{
  margin-left: -10px;
  .ngx-pagination{
    padding: 0;
    display: flex;
    &:before {
      content: "";
    }
    .current{
      background-color:transparent;
      color: $kx-red;
      background-color: $kx-selection-grey;
    }
    li{
      margin: 10px;
      padding: 0;
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      a{
        color: $kx-grey;
        padding: 0;
      }
      &:active{
        background-color: transparent;
      }
      &.pagination{
        &-next{
          a{
            background-color: $kx-selection-grey;
            height: 24px;
            display: block;
            &:after{
              content: "";
              width: 24px;
              height: 24px;
              background-image: url('assets/icons/expand_less-24px.svg');
              transform: rotate(90deg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              margin: 0;
            }
          }
          &.disabled{
            background-color: $kx-selection-grey;
            height: 24px;
            display: block;
            opacity: 0.5;
            &:after{
              content: "";
              width: 24px;
              height: 24px;
              background-image: url('assets/icons/expand_less-24px.svg');
              transform: rotate(90deg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              margin: 0;
            }
          }
        }
        &-previous{
          a{
            background-color: $kx-selection-grey;
            height: 24px;
            display: block;
            &:before{
              content: "";
              width: 24px;
              height: 24px;
              background-image: url('assets/icons/expand_less-24px.svg');
              transform: rotate(-90deg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              margin: 0;
            }
          }
          &.disabled{
            background-color: $kx-selection-grey;
            height: 24px;
            display: block;
            opacity: 0.5;
            &:before{
              content: "";
              width: 24px;
              height: 24px;
              background-image: url('assets/icons/expand_less-24px.svg');
              transform: rotate(-90deg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

// Dropdown
.dropdown{
  .dropdown-menu{
    text-align: center;
    margin-right: -20px;
    border-radius: 0px;
    box-shadow: 0px 2px 10px #00000027;
    padding: {
      top: 15px;
      bottom: 15px;
    }
    &[x-placement = "top-end"] {
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        right: 8%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: #fff;
        border-bottom: 0;
        margin-left: -21px;
        margin-bottom: -10px;
      }
    }
    &[x-placement = "bottom-end"] {
      &:after{
        content: '';
        position: absolute;
        right: 8%;
        top: 0;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #fff;
        border-top: 0;
        margin-left: -21px;
        margin-top: -10px;
      }
    }
    .dropdown-item{
      padding: {
        top: 7px;
        bottom: 7px;
        left: 50px;
        right: 50px;
      }
      width: 100%;
      &:hover{
        background: $kx-red;
        color: #fff;
      }
    }
  }
}

// Radio-Button
.radio-wrapper{
  display: inline-flex;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin-left: 8px;
  input[type="radio"]{
    display: none;
    &:checked ~ .radio-button {
      border-color: $kx-red;
      &:after{
        display: block;
      }
      &:hover{
        border-color: rgba($kx-red, 0.8);
        &:after{
          border-color: rgba($kx-red, 0.8);
        }
      }
    }
    &:disabled ~ .radio-button {
      border: 1px solid rgba($kx-input-border,0.2);
      background-color: $kx-selection-grey;
    }
    &:disabled:checked ~ .radio-button {
      border-color: rgba($kx-red,0.5);
      &:after{
        border-color: rgba($kx-red, 0.5);
      }
    }
  }
  .radio-button {
    display: inline-flex;
    height: 20px;
    width: 20px;
    border: 1px solid $kx-input-border;
    border-radius: 50%;
    &:hover{
      border-color: $kx-dark-grey;
    }
    &:after{
      margin: auto;
      content: "";
      display: none;
      width: 10px;
      height: 10px;
      background-color: $kx-red;
      border-radius: 50%;
    }
  }
}

// Toggle-Button
.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 28px;
  & input{
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: $kx-red;
      &:before {
        -webkit-transform: translateX(35px);
        -ms-transform: translateX(35px);
        transform: translateX(35px);
      }
      &:after {
        content: "ON";
        left: 12px;
      }
    }
    &:disabled ~ .slider{
      border: 1px solid rgba($kx-input-border,0.2);
      background-color: #fff;
      &:before {
        background-color: rgba($kx-input-border,0.2);
        bottom: 2px;
      }
      &:after {
        color: $kx-dark-grey;
        bottom: 3px;
      }
    }
    &:disabled:checked ~ .slider {
      background-color: rgba($kx-red,0.5);
      &:before{
        background-color: #fff;
      }
      &:after{
        color: #fff;
      }
    }
  }
  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $kx-dark-grey;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    &:before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
    &:after {
      position: absolute;
      color: #fff;
      content: "OFF";
      font-size: 13px;
      left: 30px;
      bottom: 4px;
    }
  }
}
// Tags
.tag{
  position: relative;
  width: 95px;
  height: 33px;
  border-radius: 20px;
  text-align: center;
  padding-top: 5px;
  font-size: 13px;
  &.enabled {
    border: 1px solid $kx-red;
    color: $kx-red;
  }
  &.selected {
    background-color: $kx-red;
    padding-left: 8px;
    padding-top: 6px;
    color: #fff;
    &:after {
      display: block;
    }
  }
  &:after{
    content: "";
    position: absolute;
    display: none;
    left: 15px;
    top: 10px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Spinner
.loader {
  border: 0.5rem solid $kx-light-gray;
  border-top: 0.5rem solid $kx-red;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;
  animation: spin 1.5s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1.8rem;
  margin-top: -1.8rem;
}

.inline-loader {
  display: inline-block;
  border: 0.2rem solid $kx-light-gray;
  border-top: 0.2rem solid $kx-red;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

// Icons
.icon{
  position: relative;
  &::before{
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.small-icon::before {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -0.125rem;
  }
}
.panorama-icon{
  background-image: url("assets/icons/panorama-24px.svg");
  background-repeat: no-repeat;
}
.checkmark-icon-red{
  background-image: url("assets/icons/checkmark_red.svg");
  background-repeat: no-repeat;

  &.large {
    background-size: cover;
    background-position: center center;
  }
}
.checkmark-icon-green{
  background-image: url("assets/icons/checkmark_green.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.checkmark-icon-blue{
  background-image: url("assets/icons/checkmark_blue.svg");
}
.exclamation-mark-icon{
  background-image: url("assets/icons/exclamation_mark.svg");
}
.download-icon-red::before{
  background-image: url("assets/icons/download-icon-red.svg");
  cursor: pointer;
}
.arrow-icon-red-right::before{
  background-image: url("assets/icons/expand_less-24px.svg");
  cursor: pointer;
  transform: rotate(90deg);
}
.arrow-icon-red-left::before{
  background-image: url("assets/icons/expand_less-24px.svg");
  cursor: pointer;
  transform: rotate(-90deg);
}
.arrow-icon-red-down::before{
  background-image: url("assets/icons/expand_less-24px.svg");
  cursor: pointer;
  transform: rotate(180deg);
}
.arrow-icon-red-up::before{
  background-image: url("assets/icons/expand_less-24px.svg");
  cursor: pointer;
}
.info-icon-red{
  cursor: pointer;
  &::before{
    border: 8px solid $kx-red;
    border-radius: 10px;
    width: 1rem;
    height: 1rem;
  }
  &::after{
    bottom: -1;
    position: absolute;
    margin-left: -10px;
    content: url("assets/icons/info.svg");
  }
}
.zoom-in-icon-red::before{
  background-image: url("assets/icons/zoom_in.svg");
}
.info-icon-blue::before{
  background-image: url("assets/icons/info-24px-5.svg");
  margin-right: 15px;
}
.triangle-icon-red-up {
  &::before {
    background-image: url("assets/icons/expand-more-triangle-up.svg");
    cursor: pointer;
  }
}
.triangle-icon-red-right::before {
  background-image: url("assets/icons/expand-more-triangle-up.svg");
  transform: rotate(90deg);
  cursor: pointer;
}
.triangle-icon-red-down::before {
  background-image: url("assets/icons/expand-more-triangle-up.svg");
  transform: rotate(180deg);
  cursor: pointer;
}
.delete-bin-icon-red {
  &::before {
    background-image: url("assets/icons/delete-bin-24px.svg");
  }
}

.drag-and-drop-icon {
  &::before {
    background-image: url("assets/icons/drag_and_drop-24px.svg");
    cursor: pointer;
  }
}
.lock-icon {
  &::before {
    background-image: url("assets/icons/lock-red-24px.svg");
  }
}
.kebab-menu-icon {
  &::before {
    background-image: url("assets/icons/more_vertical-24px.svg");
    cursor: pointer;
  }
}

.add::before {
  background-image: url("assets/icons/add-24px.svg");
}
.folder-icon::before {
  background-image: url("assets/icons/folder_open-62px.svg");
  width: 80px;
  height: 80px;
}
.add-icon-grey::before {
  background-image: url("assets/icons/add-grey-64px.svg");
  width: 64px;
  height: 64px;
}
.add-icon-red::before {
  background-image: url("assets/icons/add-red-64px.svg");
  width: 64px;
  height: 64px;
}
.warning-icon::before {
  background-image: url("assets/icons/warning-24px.svg");
}
.close-icon::before {
  background-image: url("assets/icons/close-24px.svg");
}
.create-icon::before {
  background-image: url("assets/icons/create-24px.svg");
}


.active-part{
  filter: opacity(0.9) brightness(73%) saturate(280%)
}
.active-part-dark{
  filter: opacity(0.9) brightness(120%) saturate(180%)
}
.active-part-darker{
  filter: opacity(0.9) brightness(430%) saturate(200%) brightness(70%) saturate(180%)
}
.semi-transparent-part{
  opacity: 0.8
}
.transparent-part{
  opacity: 0.4
}

// Opacity
.opacity-1{
  opacity: 0.1;
}
.opacity-2{
  opacity: 0.2;
}
.opacity-3{
  opacity: 0.3;
}
.opacity-4{
  opacity: 0.4;
}
.opacity-5{
  opacity: 0.5;
}
.opacity-6{
  opacity: 0.6;
}
.opacity-7{
  opacity: 0.7;
}
.opacity-8{
  opacity: 0.8;
}
.opacity-9{
  opacity: 0.9;
}
.opacity-10{
  opacity: 1;
}

.main-window-with-infobar{
  margin-right: 360px;
}
.info-bar-col{
  width: 360px;
  right: 0;
  margin-top: -105px;
  z-index: 2;
}
// Info container
.info-container {
  padding: 15px;
  background-color: $kx-info-blue;
  font-size: 14px;

  > span {
    vertical-align: middle;
    line-height: 1;
  }
}

.fw-bold {
  font-weight: bold;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.mtb-1px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.position-relative {
  position: relative;
}

.modal-content {
  border: 0;
  border-radius: $border-radius;
}

.progress {
  border-radius: 0;
  height: 0.25rem;
}

// Feedback button

.feedbackButtonArea {
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: right;
  z-index: 10;
}
  
.icon-feedback-button::before {
  background-image: url("assets/textsms_black_24dp.svg");
  position: relative;
  top: 0.4em;
}