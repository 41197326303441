@import "colors";

.font-size-xs {
  font-size: .75rem;
}
.font-size-s {
  font-size: .875rem;
}
.font-size-m {
  font-size:  1rem;
}
.font-size-l {
  font-size: 1.25rem;
}
.font-size-xl {
  font-size: 1.5rem;
}

.label-font {
  font-weight: 500;
  font-size: 1.1rem;
  color: $secondary;
}

.line-height-1 {
  line-height: 1;
}
