$kx-blue: #003862;
$kx-dark-blue: #16386f;
$kx-light-blue: #b7c6e0;
$kx-red: #932c55;
$kx-gray: #787877;
$kx-light-gray: #cbced1;

$kx-dashboard-blue: #9BBCD8;

$kx-dark-grey: #4a4a4a;
$kx-grey:#787877;
$kx-light-grey: #A7A7A7;
$kx-input-border: #78787780;
$kx-input-border-hover: #787877CC;
$kx-input-text: #646464CC;
$kx-button-hover: #932c55CC;
$kx-button-pressed: #741e40;
$kx-error: #e74c3c;
$kx-secondary-color: #EDC400;
$kx-success: #3ead6d;
$kx-warning: #f7d02c;
$kx-selection-grey: #F2F2F2;
$kx-info-blue: #E1EBF3;
$kx-button-light-blue: #E1E8ED;

$main-background: #F4F6Fa;

$primary: $kx-red;
$secondary: $kx-blue;
$danger: $kx-error;
$info: $kx-dark-blue;

$notifier-default-background-color: #444;
$notifier-error-background-color: $kx-red;
$notifier-info-background-color: $kx-blue;
$notifier-success-background-color: #5CB85C;
$notifier-warning-background-color: #F0AD4E;

$theme-colors: (
    "transparent": transparent,
    "kxselectiongrey": $kx-selection-grey,
    "kxlightgrey": $kx-light-grey,
    "kxdarkgrey": $kx-dark-grey,
    "kxdarkblue": $kx-dark-blue,
    "kxgrey": $kx-grey,
    "kxdashboardblue": $kx-dashboard-blue,
    "kxerror": $kx-error,
    "kxsuccess": $kx-success,
    "background": $main-background
);
